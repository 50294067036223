/***************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import '@angular/localize/init';
(window as any).global = window;

// This file includes polyfills needed by Angular 2 and is loaded before
// the app. You can add your own extra polyfills to this file.
import 'core-js/es6/symbol';
import 'core-js/es6/object';
import 'core-js/es6/function';
import 'core-js/es6/parse-int';
import 'core-js/es6/parse-float';
import 'core-js/es6/number';
import 'core-js/es6/math';
import 'core-js/es6/string';
import 'core-js/es6/date';
import 'core-js/es6/array';
import 'core-js/es6/regexp';
import 'core-js/es6/map';
import 'core-js/es6/set';
// import 'core-js/es6/reflect';
import 'core-js/es7/reflect';
import 'zone.js';

import 'web-animations-js/web-animations.min';  // Run `npm install --save classlist.js`.  // Run `npm install --save web-animations-js`.  // Run `npm install --save web-animations-js`.

// Array.includes
import './polyfills/array.includes.js';
